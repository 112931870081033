import React, { useState, useEffect } from "react"
import { FaHeart, FaRegHeart } from "react-icons/fa"
import {
  PieChart,
  Pie,
  Sector,
  Cell,
  Legend,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Area,
  AreaChart,
  ResponsiveContainer,
  BarChart,
  Bar,
} from "recharts"
import { useStaticQuery, graphql } from "gatsby"
import resolveConfig from "tailwindcss/resolveConfig"
import tailwindConfig from "../../tailwind.config.js"

const fullConfig = resolveConfig(tailwindConfig)

import {
  getFeatures,
  loginAnon,
  onAuthStateChanged,
  subscribeToLikeTotals,
  subscribeToUser,
} from "./utils"
import timTitleImg3 from "../images/sparkleTitle_730.png"
import loadingTree from "../images/loadingTree6.gif"

const blue = fullConfig.theme.colors.blue
const indigo = fullConfig.theme.colors.indigo
const black = fullConfig.theme.colors.black
const red = fullConfig.theme.colors.red
const yellow = fullConfig.theme.colors.yellow
const purple = fullConfig.theme.colors.purple
const gray = fullConfig.theme.colors.gray
const green = fullConfig.theme.colors.green
const pink = fullConfig.theme.colors.pink
const white = fullConfig.theme.colors.white
console.log(fullConfig.theme.colors)
const COLORS = [
  blue[500],
  red[500],
  white,
  indigo[500],
  black[500],
  yellow[500],
  green[500],
  purple[500],
  pink[500],
  gray[500],
]

const RADIAN = Math.PI / 180

const renderCustomizedLabel = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
  index,
}) => {
  const radius = innerRadius + (outerRadius - innerRadius) * 0.5
  const x = cx + radius * Math.cos(-midAngle * RADIAN)
  const y = cy + radius * Math.sin(-midAngle * RADIAN)

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? "start" : "end"}
      dominantBaseline="central"
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  )
}

function useAuth() {
  const [authAttempted, setAuthAttempted] = useState(false)
  const [auth, setAuth] = useState(null)

  useEffect(() => {
    return onAuthStateChanged(auth => {
      setAuthAttempted(true)
      setAuth(auth)
    })
  }, [])

  return { auth, authAttempted }
}

function Stats() {
  const [features, setFeatures] = useState([])
  const [likeTotals, setLikeTotals] = useState([])
  const { auth, authAttempted } = useAuth()
  const [user, setUser] = useState({})

  loginAnon().catch(error => {
    console.log(error.message)
    setError(error)
  })

  useEffect(() => {
    if (auth) {
      return getFeatures().then(fs => setFeatures(fs))
    }
  }, [auth])

  useEffect(() => {
    if (auth) {
      return subscribeToLikeTotals(setLikeTotals)
    }
  }, [auth])

  useEffect(() => {
    if (auth) {
      return subscribeToUser(auth.uid, setUser)
    }
  }, [auth])

  if (!authAttempted) {
    return <LoadingTree />
  }

  const totalTotal = Object.values(likeTotals).reduce((acc, t) => acc + t, 0)
  const pieData = features
    .map(f => ({
      name: f.name,
      value: likeTotals[f.id] / totalTotal || 0,
    }))
    .sort((a, b) => b.value - a.value)
  // const areaData = features.map(f => ({
  //   name: f.name,
  //   value: likeTotals[f.id] || 0,
  // }))
  const barData = features.map(f => ({
    name: f.name,
    value: likeTotals[f.id] || 0,
  }))

  return (
    <div className="flex flex-col items-center justify-center w-screen">
      <div className="min-w-min max-w-xl mt-4 sm:mt-8 mx-auto">
        <div className="mx-auto my-0 mb-8">
          <img
            alt="Lights on Leyden"
            className="mx-auto"
            loading="lazy"
            src={timTitleImg3}
          />
        </div>
        <h1 className="border-gray-400 border-t-4 border-b-4 mb-8 mx-auto rounded-md text-2xl text-blue-50 text-center w-1/2">
          2020 Stats
        </h1>
        <div className="mx-0 sm:mx-auto w-full">
          {/* <AreaChart
            width={1700}
            height={400}
            data={areaData}
            margin={{
              top: 10,
              right: 30,
              left: 0,
              bottom: 0,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Area
              type="monotone"
              dataKey="value"
              stackId="1"
              stroke="#8884d8"
              fill="#8884d8"
            />
          </AreaChart> */}
          <BarChart
            width={800}
            height={300}
            data={barData}
            margin={{
              top: 5,
              right: 30,
              left: 20,
              bottom: 5,
            }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" label={{ angle: -45 }} />
            <YAxis
              label={{ value: "Likes", angle: -90, position: "insideLeft" }}
            />
            <Tooltip />
            <Bar dataKey="value" fill="#8884d8" />
          </BarChart>

          <PieChart width={400} height={400}>
            <Pie
              data={pieData}
              cx={200}
              cy={200}
              outerRadius={80}
              dataKey="value"
              startAngle={180}
              endAngle={-360}
              label
            >
              {pieData.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={COLORS[index % COLORS.length]}
                />
              ))}
            </Pie>
          </PieChart>
        </div>
      </div>
    </div>
  )
}

function LoadingTree() {
  return (
    <div
      style={{
        display: "grid",
        placeItems: "center",
        minHeight: "100vh",
        height: "-webkit-fill-available",
        width: "100vw",
      }}
    >
      <img src={loadingTree} />
    </div>
  )
}

export default Stats

// <picture>
//   <>
//     {pic.srcs
//       .filter(src => Boolean(src.res))
//       .map(src => {
//         console.log(`d`, data)
//         console.log(src)
//         return (
//           // <Img
//           //   fluid={data[baseSrc.queryName].childImageSharp.fluid}
//           //     key={src.queryName}
//           //   alt={baseSrc.caption}
//           //   w="100%"
//           // />
//           <source
//             key={src.queryName}
//             media={`(min-width: ${src.res}px)`}
//             srcSet={`features/${src.queryName}`}
//           />
//         )
//       })}
//   </>
//   <Img
//     fluid={data[baseSrc.queryName].childImageSharp.fluid}
//     alt={baseSrc.caption}
//     w="100%"
//   />
// </picture>
